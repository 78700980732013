<template>
    <TooltipErrors :class="['w-full', `max-w-[${maxWidth}]`]" filter :model="errors">
        <Select
            v-model="model"
            :options
            :option-label="optionLabel"
            :option-value="optionValue"
            :placeholder
            :disabled
            filter
            :show-clear="isClear"
            :unstyled="true"
            :pt="pt"
        >
            <template #dropdownicon>
                <span class="flex icon-chevron-down icon_small" />
            </template>

            <template #clearicon="{ clearCallback }">
                <span class="flex items-center justify-center icon-close icon_small" @click="clearCallback" />
            </template>

            <template #emptyfilter>
                <p class="not-found-label">{{ $t('No results found') }}</p>
            </template>

            <template #empty>
                <p class="not-found-label">{{ $t('The list is empty') }}</p>
            </template>

            <template #filtericon>
                <span class="flex icon-search icon_small p-dropdown-filter-icon" />
            </template>
        </Select>
    </TooltipErrors>
</template>

<script setup lang="ts">
import type { NumberOrNull, OptionType } from '@ts/types/app.type';
import Select from 'primevue/select';
import TooltipErrors from '@c/TooltipErrors.vue';

/** Interfaces **/
interface Props {
    placeholder?: string;
    options: OptionType[];
    disabled?: boolean;
    errors?: string[];
    optionLabel?: string;
    optionValue?: string;
    isClear?: boolean;
    maxWidth?: string;
}

/** Props **/
const model = defineModel<NumberOrNull | undefined>({ required: true, default: null });

const props = withDefaults(defineProps<Props>(), {
    placeholder: '-',
    options: () => [],
    disabled: false,
    errors: () => [],
    optionLabel: 'label',
    optionValue: 'key',
    isClear: false,
    maxWidth: '100%',
});

const { errors, maxWidth } = toRefs(props);

/** Data **/
const pt = readonly({
    root: `w-full dropdown-search ${!toValue(model) ? 'empty' : ''} ${toValue(errors).length ? 'invalid' : ''}`,
    label: 'dropdown-search__label',
    dropdown: 'dropdown-search__dropdown',
    overlay: `dropdown-search__overlay max-w-[${toValue(maxWidth)}]`,
    header: 'dropdown-search__header',
    list: 'dropdown-search__list',
    listContainer: 'dropdown-search__list-container',
    option: 'dropdown-search__option',
    optionLabel: 'dropdown-search__option-label',
    pcFilter: {
        root: 'dropdown-search__input',
    },
    pcFilterContainer: {
        class: 'dropdown-search__filter',
    },
    pcFilterIconContainer: {
        class: 'dropdown-search__filter-icon',
    },
});
</script>

<style lang="scss">
@use "assets/styles/variables" as *;

* {
    outline: none;

    &:focus-visible {
        outline: none !important;
    }
}

.dropdown-search {
    padding: 4px;
    border-radius: 4px;
    border: 1px solid $color-natural-default;
    display: flex;
    align-items: center;
    cursor: pointer;

    &.invalid {
        border-color: $color-error-default;
    }

    &.empty {
        //border-color: $color-secondary-default;
    }

    &__dropdown {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__label {
        color: $color-primary-darker;
        font-size: $size-14;
        font-weight: $weight-500;
        line-height: $line-height-140;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__overlay {
        border-radius: $border-radius;
        box-shadow:
            0 1px 3px 1px rgba(4, 27, 45, 0.15),
            0 1px 2px 0 rgba(4, 27, 45, 0.3);
        background-color: $color-white;
        margin-top: 1px;
    }

    &__header {
        position: relative;

        [data-pc-name='pcfiltercontainer'] {
            display: flex;
            position: relative;
            padding: 4px;
        }

        [data-pc-name='pcfiltericoncontainer'] {
            display: flex;
            position: absolute;
            right: 8px;
            top: 50%;
            transform: translateY(-50%);
            user-select: none;
            pointer-events: none;

            .icon-search {
                &:before {
                    color: $color-natural-default;
                }
            }
        }
    }

    &__input {
        border: 1px solid $color-natural-default;
        border-radius: 4px;
        width: 100%;
        padding: 4px 32px 4px 4px;
        font-size: 12px;
    }

    &__list {
        &-container {
            overflow-y: auto;

            .not-found-label {
                padding: 4px;
                font-size: $size-14;
                font-weight: $weight-500;
                line-height: $line-height-140;
            }
        }
    }

    &__option {
        padding: 4px 8px;
        cursor: pointer;
        display: flex;

        &:hover {
            background-color: $color-primary-light;
        }

        &[aria-selected='true'] {
            .dropdown-search__option-label {
                color: $color-secondary-default;
            }
        }

        &-label {
            color: $color-natural-darker;
            font-size: $size-12;
            font-weight: $weight-400;
            line-height: $line-height-130;
        }
    }
}
</style>
